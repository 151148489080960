


































import { Component } from 'vue-property-decorator'

import { logger } from '../../../../support'
import { PaymentMethodPayload } from '../../../../contexts'

import {
  PaymentMethod,
  Przelewy24PaymentMethodDefinition
} from '../PaymentStep/PaymentStep.contracts'
import {
  defaultPaymentDriver,
  PaymentDriver,
  paymentsDriversRegistry
} from '../PaymentStep/drivers/drivers'

import { PaymentStep } from '../PaymentStep'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<PaymentStepTwo>({
  name: 'PaymentStepTwo',
  components: { PaymentStep },
  created (): void {
    this.loadPaymentsMethods()
  }
})
export class PaymentStepTwo extends PaymentStep {
  public paymentMethods: Przelewy24PaymentMethodDefinition[] = []

  public get isSelectedPayment (): boolean {
    return this.selectedPayment !== null && this.selectedPayment.code.length > 0
  }

  /**
   * Translate `Przelewy24` methods to common payment method.
   */
  public get translatedPrzelewy24ToPaymentMethods (): PaymentMethod[] {
    return this.paymentMethods.map((method) => {
      return {
        id: String(method.id),
        image: method.imgUrl,
        title: this.$t(`front.checkout.organisms.PaymentStep.Przelewy24.${method.id}.title`),
        description:
          this.$t(`front.checkout.organisms.PaymentStep.Przelewy24.${method.id}.description`),
        driver: paymentsDriversRegistry[PaymentDriver.Przelewy24SingleMethod]
      }
    })
  }

  public get methods (): PaymentMethod[] {
    let unorderedMethods: PaymentMethod[] = this.cart?.availablePaymentMethods
      .filter(({ code }) => this.supportedPaymentMethods.includes(code))
      .map((method: PaymentMethodPayload<string>) => ({
        id: method.code,
        title: this.$t(`front.checkout.organisms.PaymentStep.method.${method.code}.title`),
        description: this.$t(`front.checkout.organisms.PaymentStep.method.${method.code}.description`),
        image: this.supportedPaymentMethodsIcons[method.code],
        driver: method.code in paymentsDriversRegistry ? paymentsDriversRegistry[method.code]
          : defaultPaymentDriver
      }))
    // Join magento based payment methods with inner Przelewy24 methods.
    unorderedMethods = [...unorderedMethods, ...this.translatedPrzelewy24ToPaymentMethods]

    return this.supportedPaymentMethods
      .map((code) => unorderedMethods.filter((m) => m.id === code)[0])
      .filter(item => item)
  }

  public onSaving (value: boolean): void {
    this.$emit('saving', value)
  }

  protected async loadPaymentsMethods (): Promise<void> {
    try {
      this.$emit('saving', true)
      this.paymentMethods = await this.checkoutService.loadPaymentMethods(this.cart.total.value)
    } catch (e) {
      logger(e, 'error')
    } finally {
      this.$emit('saving', false)
    }
  }
}

export default PaymentStepTwo
